import React, { FC, useState, useEffect, useRef, useCallback, useMemo, createElement as reactElement } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'

import { loadArrayComponent, Settings } from '../config'
import { IdentityLanding } from '../components/IdentityLanding/IdentityLanding'
import { DISPLAY_SOURCES } from '../pages/setup-identity'
import Router from '../shared/router'

const STYLE = require('!!css-loader?{"sourceMap":false}!sass-loader!../assets/stylesheets/array.scss').at(0).at(1)

type AccessDataQuery = {
  account: { identisafeUserId?: string }
  arrayAccount: { clientKey: string, token: string, isAvailable: boolean, isSetup: boolean }
}
const accessDataQuery = gql`
  query($scope: String!, $source: String!) {
    account { identisafeUserId }
    arrayAccount(scope: $scope) { clientKey token isAvailable(source: $source) isSetup(source: $source) }
  }
`

type UpdateAccountMutation = { result: boolean }
type UpdateAccountMutationVariables = { scope: string, token?: string, source?: string }
const updateAccountMutation = gql`
  mutation($scope: String!, $token: String, $source: String) {
    result: updateArrayAccount(scope: $scope, token: $token, source: $source)
  }
`

type RefreshTokenMutation = { token: string }
const refreshTokenMutation = gql`
  mutation($scope: String!) { token: refreshArrayToken(scope: $scope) }
`

type UpgradeIDProtectionMutation = { token: string }
const upgradeIDProtectionMutation = gql`
  mutation { result: upgradeIdentityProtection }
`

type Props = {
  name: string,
  hasAccess: boolean,
  oldSetup: boolean,
  isSetup: boolean,
  upgrade: () => void,
  setup: () => Promise<any>,
  config: Record<string, string | undefined>
}
const DisplayComponent: FC<Props> = ({ name, hasAccess, oldSetup, isSetup, upgrade, setup, config }) => {
  const [isReady, setIsReady] = useState(isSetup)
  const callSetup = () => (setup().then(() => setIsReady(true)))
  const source = DISPLAY_SOURCES[name]

  return (
    (!hasAccess && (
      <IdentityLanding
        key="default"
        title={source.landingTitle}
        buttonText={source.landingButtonText}
        steps={source.steps}
      />
    )) || (oldSetup && (
      <IdentityLanding
        key="upgrade"
        title="We have updated our identity protection."
        buttonText="Upgrade Protection"
        onClick={upgrade}
      />
    )) || (!isReady && (
      <IdentityLanding
        key="activate"
        buttonText="Enable"
        onClick={callSetup}
      />
    )) || (
      <>{reactElement(`array-${name}`, { ...config, 'class': 'array-component' })}</>
    )
  )
}

export default function useArrayComponent(name: string, scope: 'user' | 'account' = 'account', authenticated = true) {
  const [isLoading, setIsLoading] = useState(true)
  const [settings, setSettings] = useState<Record<string, string | undefined>>({})
  const events = useRef(new Map<string, any[]>())

  const variables = useMemo(() => ({ scope, source: name }), [scope, name])
  const { data: accessData } = useQuery<AccessDataQuery>(accessDataQuery, { variables, fetchPolicy: 'network-only' })
  const [callRefreshToken] = useMutation<RefreshTokenMutation>(refreshTokenMutation)
  const [callUpgrade] = useMutation<UpgradeIDProtectionMutation>(upgradeIDProtectionMutation)
  const [callUpdateAccount] = useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(updateAccountMutation)

  const redirectSetup = useCallback(() => {
    Router.redirect(`/setup-identity?from=${name}&for=${scope}`)
  }, [name])

  const rootEvent = useRef((arrayEvent: any) => {
    const { tagName, event, metadata } = arrayEvent.detail
    const eventCallbacks = events.current.get(event)
    eventCallbacks?.forEach((callback: any) => { callback(metadata, tagName) })
  })

  const [clientKey, userToken, hasAccess, oldSetup, isSetup] = useMemo(() => {
    if (!accessData?.account) {
      return []
    }

    const { clientKey, token, isAvailable, isSetup } = accessData.arrayAccount

    return [
      clientKey,
      token,
      !authenticated || isAvailable,
      !!accessData.account.identisafeUserId,
      !authenticated || isSetup,
    ]
  }, [accessData?.account])

  const enableFeature = useCallback(async () => {
    await callUpdateAccount({ variables: { scope, source: name } })
  }, [settings, accessData])

  const createElement = useCallback((props?: Record<string, string | undefined>) => (
    <DisplayComponent
      name={name}
      hasAccess={!!hasAccess}
      oldSetup={!!oldSetup}
      isSetup={!!isSetup}
      upgrade={async () => {
        await callUpgrade()
        redirectSetup()
      }}
      setup={enableFeature}
      config={{ ...props, ...settings }}
    />
  ), [accessData?.account, settings])

  const updateToken = useCallback((value: string) => {
    setSettings({ ...settings, userToken: value })
  }, [settings])

  const saveToken = useCallback(async (value: string) => {
    await callUpdateAccount({ variables: { scope, token: value } })
    updateToken(value)
  }, [settings])

  const addEventListener = useCallback((event: string, callback: any) => {
    if (!events.current.has(event)) {
      events.current.set(event, [])
    }

    events.current.get(event)!.push(callback)
  }, [])

  const attachStyles = useCallback((_: any, tagName: string) => {
    const element = document.querySelector(tagName)
    if (element?.shadowRoot && !element.shadowRoot.querySelector('#vs-core-custom')) {
      const style = document.createElement('style')
      style.id = 'vs-core-custom'
      style.innerHTML = STYLE
      element.shadowRoot.appendChild(style)
    }
  }, [])

  useEffect(() => {
    if (hasAccess !== true) {
      setIsLoading(hasAccess === undefined)
      return
    }

    if (oldSetup) {
      if (name === 'identity-protect') {
        Router.redirect('/identity-security')
      }

      setIsLoading(false)
      return
    } else if (authenticated && !userToken) {
      redirectSetup()
      return
    }

    addEventListener('logout', () => {
      setIsLoading(true)
      callRefreshToken({ variables: { scope } }).then(result => {
        if (result.data?.token) {
          updateToken(result.data.token)
          setIsLoading(false)
        }
      }).catch(() => {
        redirectSetup()
      })
    })

    addEventListener('component-visible-in-viewport', attachStyles)

    const newSettings: typeof settings = { appKey: Settings.arrayKey, userToken }
    if (Settings.arrayDomain?.includes('sandbox')) {
      newSettings.sandbox = 'true'
    }

    setSettings(newSettings)
    window.addEventListener('array-event', rootEvent.current)
    loadArrayComponent(name).then(() => setIsLoading(false))

    return () => window.removeEventListener('array-event', rootEvent.current)
  }, [accessData?.account])

  return { clientKey, isLoading, saveToken, updateToken, addEventListener, createElement }
}
