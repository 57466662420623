import React, { FC } from 'react'
import { Coupon } from '../../utils/coupon'
import { toMoneyWithoutCalculation as toMoney, toPercent } from '../../shared/format'

const styles = require('./BlackFridayBanner.module.scss')
const LockIcon = require('~assets/images/icons/tax-lock.svg')

type Props = { coupon?: Coupon; title?: string; }

export const TaxSeasonBanner: FC<Props> = ({ coupon, title = 'Tax Season Special' }) => {
  const couponDiscount = (): string => {
    if (!coupon?.discountType) {
      return toMoney(0)
    } else if (coupon.discountType === 'FIXED_AMOUNT') {
      return toMoney(coupon.discountAmount || 0)
    } else {
      return toPercent(coupon.discountPercentage || 0, 0)
    }
  }

  return (
    <div className={`${styles.content} ${styles.new}`}>
      <img className={styles.icon} src={LockIcon} alt="Percentage Icon" />
      <div className={styles.text}>{title}</div>
      <div>{couponDiscount()}</div>
    </div>
  )
}
