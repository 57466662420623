import React, { useCallback, useMemo } from 'react'
import Markdown from 'react-markdown'
import { Plan, PlanSellingPoint } from '../../../type'
import { Checkbox, Label } from 'semantic-ui-react'
import { ChargebeeCoupon } from '../../../utils/coupon'
import { toMoney, toPercent } from '../../../shared/format'
import { calculateCouponDiscount, generateFeaturesList } from '../../../graphql/checkout'
import Decimal from 'decimal.js'
import { SimpleBox } from '../../SimpleBox'
import { Discount } from '../../../fragments/modal/payment/DiscountNew'
import { CheckoutConfiguration, Feature } from '../../../types/checkout'
import { trackBeginCheckout } from '../../../utils/tracking'
import Cookies from 'universal-cookie'
import { useTranslation } from 'react-i18next'

const cookies = new Cookies()

const PlanCheckMarkIcon = require('~assets/images/icons/plan-check-mark.svg')
const AlertIcon = require('~assets/images/icons/icon-alert.svg')
const ShieldRedIcon = require('~assets/images/icons/shield-red.svg')

type EXTRA_USER_ADDON = {
  id: string;
  name: string;
  itemId: string;
  period: number;
  periodUnit: number;
  price: string;
}

interface SummaryCardProps {
  plan: Plan;
  extraUserAddon?: EXTRA_USER_ADDON
  handleToggleAddUser: () => void
  addAnotherUser?: boolean
  coupon?: ChargebeeCoupon
  activateNow: boolean
  freeMonths: number
  priceTotal: Decimal
  selectedFeatures: Feature[]
  priceWithAddon: Decimal
  checkoutConfig?: CheckoutConfiguration
  firstPlanId: string
  setCoupon: (coupon?: ChargebeeCoupon) => void
  allowCoupon?: boolean
}

const SummaryCard: React.FC<SummaryCardProps> = ({
  plan,
  extraUserAddon,
  handleToggleAddUser,
  addAnotherUser,
  coupon,
  activateNow,
  freeMonths,
  priceTotal,
  selectedFeatures,
  priceWithAddon,
  firstPlanId,
  setCoupon,
  allowCoupon
}) => {
  const { t } = useTranslation()
  const isFamily = plan?.name.toLowerCase().includes('family')
  const planPeriod = plan?.period || 12
  const features: Array<PlanSellingPoint> = useMemo(() => {
    if (plan?.item) {
      const translated = t(`record.chargebee--item.${plan.item.id}.features`, { defaultValue: '' })
      const featuresList = translated ? translated.split('\n') : plan.item.featuresList
      return generateFeaturesList({ featuresList }, activateNow, t('checkout.header.trial'))
    }
    return []
  }, [plan?.item, activateNow])
  const total = priceTotal.toNumber()
  const nameParts = (plan?.name || '')?.replace(/\)$/, '')?.split('(')

  const period = { 1: 'month', 12: 'year', 24: '2 years' }[planPeriod]
  const totalPeriod = (plan?.period || 12) + ((activateNow && freeMonths) || 0)
  const { EUPOriginal, EUPDiscounted } = useMemo(() => {
    if (!extraUserAddon) {
      return { EUPDiscounted: '', EUPOriginal: '' }
    }

    const basePrice = new Decimal(extraUserAddon.price)
    const discountPrice = basePrice.minus(calculateCouponDiscount(basePrice, coupon)).toNumber()
    const options = { maximumFractionDigits: 0, minimumFractionDigits: 0 }
    return {
      EUPOriginal: toMoney(basePrice.toNumber(), options), EUPDiscounted: toMoney(discountPrice, options)
    }
  }, [extraUserAddon, coupon])

  const couponDiscount = (): string => {
    if (!coupon?.discountType) {
      return toMoney(0)
    } else if (coupon.discountType === 'FIXED_AMOUNT') {
      return toMoney(coupon.discountAmount || 0)
    } else {
      return toPercent(coupon.discountPercentage || 0, 0)
    }
  }

  const handleCouponChange = useCallback((coupon?: ChargebeeCoupon) => {
    setCoupon(coupon)

    if (coupon) {
      cookies.set('coupon', coupon.name, { path: '/' })
    } else {
      cookies.remove('coupon', { path: '/' })
    }

    const action = coupon ? 'add coupon' : 'remove coupon'
    plan && trackBeginCheckout(plan, isFamily, coupon, action)
  }, [plan])

  return (

    <div className="body-summary">
      <div className="body-summary-mobile-plan">
        <img src={ShieldRedIcon} alt="shield" width={42} />
        <span className="body-summary-mobile-plan-vs">VirtualShield</span>
        <span className="body-summary-mobile-plan-name">{nameParts[0]}</span>
      </div>
      <div className="body-summary-features">
        {features.map((feature, index) => (
          <div key={index} className="body-summary-features-item">
            <img src={PlanCheckMarkIcon} alt="check-mark" />
            <span>{feature.title}</span>
            <span className="body-summary-features-item-subTitle">{feature.subtitle}</span>
          </div>
        ))}
      </div>
      {extraUserAddon && (
        <div className="body-summary-extra-user">
          <span className="body-summary-extra-user-title">{t('checkout.sections.extra_features.title', { defaultValue: 'Extra features' })}</span>
          <div className="body-summary-extra-user-body">
            <Checkbox
              className="add-one-checkbox"
              onChange={handleToggleAddUser}
              style={{ isolation: 'isolate' }}
              checked={addAnotherUser}
              toggle
            />
            <Markdown className="checkout-add-user">
              {t('checkout.sections.summary.add_user.label', (
                (EUPOriginal !== EUPDiscounted) ? ({
                  'base_price': `~~${EUPOriginal}~~`,
                  'price': `**${EUPDiscounted}**`,
                  'period': t(`checkout.sections.summary.period.${period}`),
                }) : ({
                  'base_price': '',
                  'price': `${EUPDiscounted}`,
                  'period': t(`checkout.sections.summary.period.${period}`),
                })
              ))}
            </Markdown>
            <div className="body-summary-extra-user-body-alert alert-box">
              <Label pointing="below" className="alert-label">
                {t('checkout.sections.summary.add_user.description')}
              </Label>
              <img src={AlertIcon} alt="alert" width={16} />
            </div>
          </div>
        </div>
      )}
      {selectedFeatures.map(feature => {
        const addonPriceWithDiscount = new Decimal(feature.price)
          .mul(new Decimal(1).minus(coupon?.discountPercentage || 0))
          .minus(new Decimal(coupon?.discountAmount || 0))
        return (
          <div key={feature.chargebeeId} className="body-summary-extra-feature">
            <div className="body-summary-extra-feature-top">
              <div className="body-summary-extra-feature-name">
                <img
                  alt="check-mark"
                  className="checkout-breakdown-icon"
                  src={PlanCheckMarkIcon}
                />
                <p className="checkout-breakdown-text">{feature.title}</p>
              </div>
              <span className="checkout-breakdown-text"> {toMoney(addonPriceWithDiscount.toNumber())}</span>
            </div>
            {coupon && (
              <div className="body-summary-extra-feature-discount">
                <p className="checkout-gray-text">
                  {plan.period >= 24 ? 2 : 1}{' '}
                  {t(`checkout.sections.summary.period.${period}`)}{' '}
                  {(activateNow && freeMonths && `+ ${freeMonths} months`)} (
                  {toMoney(addonPriceWithDiscount.div(totalPeriod).toNumber())}/
                  {t('checkout.sections.summary.period.month_short')})
                </p>
                <p className="checkout-gray-text checkout-gray-text-crossed">
                  {toMoney(feature.price)}
                </p>
              </div>
            )}
          </div>
        )
      })}
      <div className="body-summary-prices">
        {coupon && (
          <SimpleBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt={2}
            width="100%"
          >
            <SimpleBox display="flex" flexDirection="row" gap={8}>
              <p className="checkout-gray-text">{t('checkout.sections.summary.discount')}</p>
              <Label className="pill" color="green" size="tiny">
                {couponDiscount()}
              </Label>
            </SimpleBox>
            <div className="text">
              <div className="text">
                {coupon ? '-' : ''}
                {toMoney(priceWithAddon.toNumber() - total)}
              </div>
            </div>
          </SimpleBox>
        )}

        <SimpleBox
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
          width="100%"
        >
          <p className="checkout-gray-text">{t('checkout.sections.summary.total_today')}</p>
          <div className="checkout-breakdown-amount">
            {toMoney(activateNow ? total : 0)}
          </div>
        </SimpleBox>
      </div>
      {allowCoupon && <div className="body-summary-coupon">
        <div className="discountForm">
          <Discount
            planId={firstPlanId}
            current={coupon}
            onCouponFound={handleCouponChange}
            onRemoveCoupon={handleCouponChange}
            showCouponInput={true}
          />
        </div>
      </div>}
    </div>
  )
}

export default SummaryCard
