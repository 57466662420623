import React, { FC } from 'react'
import { Image, Button } from 'semantic-ui-react'
import { SimpleBox } from '../SimpleBox'

const IMAGE = require('../../assets/images/identity-setup-onboarding.png')

type Params = {
  image?: any
  title?: string
  buttonText?: string
  steps?: string[]
  onClick?: () => void
}

const IdentityLanding: FC<Params> = ({ image, title, buttonText, steps, onClick }) => {
  onClick = onClick || (() => {
    // @ts-ignore
    window.fcWidget.open()
  })

  return (
    <SimpleBox className="sign-up">
      <Image src={image || IMAGE} />
      <h5>{title || 'Keep your identity secured'}</h5>
      {steps && steps.length > 0 && (
        <SimpleBox className="steps" marginTop="33px" marginBottom="33px">
          {steps.map((step, index) => (
            <SimpleBox className="step" key={index}>
              <span className="dot"></span>
              <span className="text">{step}</span>
            </SimpleBox>
          ))}
        </SimpleBox>
      )}
      <Button primary={true} size="large" onClick={onClick}>
        {buttonText || 'Upgrade Plan'}
      </Button>
    </SimpleBox>
  )
}

export { IdentityLanding }
