import React, { FC, useEffect } from 'react'
import { Container, Loader } from 'semantic-ui-react'

import { SimpleBox } from '../components/SimpleBox'
import { SimpleLayout } from '../components/SimpleLayout'
import { useNavigation } from '../hooks/useNavigation'
import useArrayComponent from '../hooks/useArrayComponent'

const CreditReport: FC = () => {
  const { isLoading, createElement } = useArrayComponent('credit-alerts', 'user')
  const { setNav, resetNav } = useNavigation()

  useEffect(() => {
    setNav('hidden')
    return resetNav
  }, [])

  if (isLoading) {
    return (
      <Container>
        <Loader active />
      </Container>
    )
  }

  return (
    <SimpleLayout>
      <SimpleBox className="identity-security">
        {createElement()}
      </SimpleBox>
    </SimpleLayout>
  )
}

export default CreditReport
