/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import ReactDOM from 'react-dom'

import { configure } from 'mobx'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { createHttpLink, ApolloClient, InMemoryCache } from '@apollo/client'

// @ts-ignore
import sha256 from 'crypto-js/sha256'
import { detect } from 'detect-browser'
import Cookies from 'universal-cookie'
import './freshchat.ts'
import './omappapi.ts'
import './i18n'

const cookies = new Cookies()
const arrayLoaded = new Set<string>()

// @ts-ignore
import Session from '~shared/storages/session'
import moment from 'moment'

configure({ enforceActions: 'observed' })

window.React = React
window.ReactDOM = ReactDOM

function generateUUID(): string {
  const browser = detect()
  const uuid = sha256(`${browser?.name}|${browser?.version}|${browser?.os}|${Date.now()}`)
  localStorage.setItem('UUID', uuid.toString())
  return uuid.toString()
}

const UUID = localStorage.getItem('UUID') || generateUUID()
cookies.set('UUID', UUID, { path: '/', maxAge: 60 * 60 * 24 * 7, domain: `${window.location.hostname.replace('my', '')}` })

const GQLLink = createHttpLink({
  uri: process.env.GRAPHQL_API_URL || `//api.${window.location.host.replace(/^my\./, '')}/`,
})

const GQLContext = setContext((_, { headers }) => {
  const authToken = Session.accessToken
  const authorization = authToken ? { Authorization: `Bearer ${authToken}` } : {}

  return {
    headers: {
      ...headers,
      'JWT-AUD': UUID,
      'X-DATE': moment().format(),
      'X-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'X-LOCALE': Session.locale,
      // 'Cf-Connecting-Ip': '201.55.164.161',
      ...authorization,
    },
  }
})

const GQLErrorHandler = onError(({ graphQLErrors }) => {
  if(graphQLErrors) {
    graphQLErrors.find(({ extensions }) => (
      extensions?.exception === 'Rails::GraphQL::UnauthorizedFieldError'
    )) && Session.clear('/')
  }
})

async function loadScript(url: string, attributes: Hash = {}, skipError = true): Promise<null> {
  return new Promise(resolve => {
    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('src', url)

    Object.keys(attributes).forEach(key => script.setAttribute(key, (attributes[key] as any)))

    script.onload = () => resolve(null)

    if(skipError) {
      script.onerror = () => resolve(null)
    }

    document.getElementsByTagName('head')[0].appendChild(script)
  })
}

export const Settings = {
  recaptchaToken: process.env.RECAPTCHA_TOKEN,
  welcomePromotionItem: process.env.WELCOME_PROMOTION_ITEM,
  authorizeNetMode: process.env.AUTHORIZENET_MODE,
  authorizeNetPublicKey: process.env.AUTHORIZENET_PUBLIC_KEY,
  authorizeNetLoginId: process.env.AUTHORIZENET_LOGIN_ID,
  preferableAffiliates: (process.env.PREFERRED_AFFILIATES || '').split(', '),
  arrayDomain: process.env.ARRAY_API_DOMAIN,
  arrayKey: process.env.ARRAY_API_KEY,
}

export const GQLClient = new ApolloClient({
  link: GQLErrorHandler.concat(GQLContext.concat(GQLLink)),
  cache: new InMemoryCache(),
})

export async function loadExternals(): Promise<void> {
  require('chargebee').init({
    site: process.env.CHARGEBEE_SITE,
    publishableKey: process.env.CHARGEBEE_PUB_KEY,
  })

  if (Settings.arrayDomain) {
    await loadScript(`//embed.${Settings.arrayDomain}/cms/array-web-component.js?appKey=${Settings.arrayKey}`)
  }

  if (process.env.POSTHOG_KEY && process.env.POSTHOG_HOST) {
    require('posthog-js').init(process.env.POSTHOG_KEY, {
      'api_host': process.env.POSTHOG_HOST,
      'person_profiles': 'identified_only',
      'capture_pageview': false,
    })
  }
}

export async function loadArrayComponent(name: string): Promise<void> {
  if (arrayLoaded.has(name)) {
    return
  }

  arrayLoaded.add(name)
  await loadScript(`//embed.${Settings.arrayDomain}/cms/array-${name}.js?appKey=${Settings.arrayKey}`)
}

export default async function loader(callback: () => void): Promise<void> {
  await Session.loadAuth()

  // Locale from HTML Tag
  const lang = document.documentElement.lang
  lang && (Session.locale = lang)

  // Currency from Meta Tag
  const currency = document.querySelector('meta[name="currency"]')?.getAttribute('content')
  currency && (Session.currency = currency)

  callback()
}

export function isEnv(value: string): boolean {
  return process.env.NODE_ENV === value || (value === 'development' && !process.env.NODE_ENV)
}

export function isChargebeeLoaded(): boolean {
  return require('chargebee').inited === true
}
