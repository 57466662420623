import React, { FC, useState, useEffect } from 'react'
import { Header, Loader } from 'semantic-ui-react'

import { SimpleBox } from '../components/SimpleBox'
import { SimpleLayout } from '../components/SimpleLayout'
import { IdentityLanding } from '../components/IdentityLanding/IdentityLanding'
import { useNavigation } from '../hooks/useNavigation'
import useArrayComponent from '../hooks/useArrayComponent'
import Notification from '../shared/notifications'
import Router from '../shared/router'
import Session from '../shared/storages/session'

export const DISPLAY_SOURCES: Record<string, {
  title: string,
  path: string,
  landingTitle: string,
  landingButtonText: string,
  steps?: string[]
}> = {
  'credit-report': {
    title: 'Credit Report',
    path: '/credit-report',
    landingTitle: 'Time to check your credit report.',
    landingButtonText: 'View Credit Report',
    steps: ['Credit Score', 'Credit History', 'Credit Factors']
  },
  'credit-alerts': {
    title: 'Credit Monitoring',
    path: '/credit-monitoring',
    landingTitle: 'Time to monitor your credit.',
    landingButtonText: 'Setup Credit Monitoring',
    steps: ['Credit Changes', 'Score Alerts', 'Regular Updates']
  },
  'identity-protect': {
    title: 'Identity Monitoring',
    path: '/id-monitoring',
    landingTitle: 'Time to protect your identity.',
    landingButtonText: 'Setup Identity Protection',
    steps: ['Personal Information', 'Dark Web Monitoring', 'Identity Alerts']
  },
  'pip-dashboard': {
    title: 'Personal Data Removal',
    path: '/personal-data-removal',
    landingTitle: 'Time to remove your personal data.',
    landingButtonText: 'Start Data Removal'
  },
  'neighborhood-watch': {
    title: 'Neighborhood Watch',
    path: '/neighborhood-watch',
    landingTitle: 'Time to monitor your neighborhood.',
    landingButtonText: 'Setup Neighborhood Watch',
    steps: ['Local Crime Reports', 'Safety Alerts', 'Neighborhood Updates']
  },
}

const SetupIdentity: FC = () => {
  const sourceType = (Router.qs.for as 'user' | 'account') || 'account'
  const {
    clientKey,
    isLoading,
    saveToken,
    addEventListener,
    createElement,
  } = useArrayComponent('account-enroll', sourceType, false)
  const [showForm, setShowForm] = useState(false)
  const { setNav, resetNav } = useNavigation()

  const source = DISPLAY_SOURCES[(Router.qs.from as string) || 'credit-report']

  useEffect(() => {
    setNav('hidden')
    addEventListener('signup', ({ userToken }: { userToken: string }) => {
      saveToken(userToken).then(() => {
        Notification.success('Account successfully setup')
        Router.redirect(source.path)
      })
    })

    return resetNav
  }, [])

  return (
    <SimpleLayout>
      <SimpleBox className="identity-security">
        <Header as="h4">{source.title}</Header>
        {(isLoading) && (
          <Loader active />
        ) || !showForm && (
          <IdentityLanding
            key="setup"
            title={source.landingTitle}
            buttonText={source.landingButtonText}
            steps={source.steps}
            onClick={() => setShowForm(true)}
          />
        ) || createElement({
          showSplash: 'false',
          uponSuccessShow: 'nothing',
          consentRequestorName: 'VirtualShield LLC.',
          consentRequestorWebsite: 'https://virtualshield.com',
          firstName: Session.profile?.firstName,
          lastName: Session.profile?.lastName,
          emailAddress: Session.profile?.email,
          clientKey: clientKey,
        })}
      </SimpleBox>
    </SimpleLayout>
  )
}

export default SetupIdentity
